<template>
  <div id="app">
    <div class="ls_flex">
      <left-bar />
      <div class="main">
        <top-bar @load="loaded = true" />
        <div style="padding-bottom: 40px" v-if="loaded">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import leftBar from "./components/layout/LeftBar.vue";
import topBar from "./components/layout/Top.vue";
export default {
  data() {
    return {
      loaded: false,
    };
  },
  components: {
    leftBar,
    topBar,
  },
};
</script>
<style lang="scss" scoped>
#app {
  .ls_flex {
    height: 100vh;
    > div {
      height: 100%;
    }
    .main {
      padding-left: 48px;
    }
  }
}
</style>
