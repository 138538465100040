<template>
  <div class="left_bar">
    <div class="first_part">
      <img src="@/assets/imgs/logo.png" />
      <el-select v-model="tenantId" placeholder="请选择" @change="verChange">
        <el-option
          v-for="item in list"
          :key="item.organizationId"
          :label="item.organizationName"
          :value="item.organizationId"
        >
        </el-option>
      </el-select>
    </div>
    <div class="menu_list">
      <div v-for="(item, index) in routers" :key="index">
        <template v-for="(sitem, sindex) in item.secRouters">
          <template v-if="!(!isTeacher && sitem.isTeacherOnly)">
            <router-link :to="sitem.path" :key="sindex" v-if="!sitem.future">
              <i class="iconfont" :class="{ [sitem.icon]: true }" />{{
                sitem.name
              }}
            </router-link>
            <div v-else :key="sindex">
              <i class="iconfont" :class="{ [sitem.icon]: true }" />{{
                sitem.name
              }}
            </div>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      tenantId: "",
      routers: [
        {
          secRouters: [
            {
              path: "/index",
              name: "后台首页",
              icon: "icon-a-xianxing_anquanbaozhangfuben58",
            },
            {
              path: "/race",
              name: "插花比赛",
              icon: "icon-a-xianxing_anquanbaozhangfuben",
            },
            {
              path: "/courseware",
              name: "教学课件",
              icon: "icon-a-xianxing_anquanbaozhangfuben25",
            },
            {
              path: "/homework",
              name: "插花作业",
              icon: "icon-a-xianxing_anquanbaozhangfuben24",
            },
          ],
        },
        {
          secRouters: [
            {
              path: "/manage",
              name: "学生管理",
              icon: "icon-xianxing-08",
              isTeacherOnly: true,
            },
            {
              path: "/work",
              name: "我的作品",
              icon: "icon-a-xianxing_anquanbaozhangfuben27",
            },
            {
              path: "/material",
              name: "素材库管理（即将更新）",
              future: true,
              icon: "icon-a-xianxing_anquanbaozhangfuben54",
              isTeacherOnly: true,
            },
          ],
        },
        {
          secRouters: [
            {
              path: "/data",
              name: "教学数据（即将更新）",
              future: true,
              icon: "icon-xianxing_anquanbaozhang",
              isTeacherOnly: true,
            },
            {
              path: "/account",
              name: "账号管理",
              icon: "icon-xianxing-14",
            },
          ],
        },
      ],
    };
  },
  mounted() {
    this.$ajax.post("user/user/getOrganizationList").then((res) => {
      res = res.data;
      let cur =  this.tenantId = res.find((item) => {
        return item.isCurrent === 1;
      })
      this.tenantId = cur||'';
      this.list = res;
    });
  },
  methods: {
    verChange(id) {
      this.$ajax
        .post("/user/user/changeOrganization", {
          organizationId: id,
        })
        .then((res) => {
          this.$message({
            message: "切换成功",
            type: "success",
          });
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.menu_list {
  > div {
    padding-bottom: 20px;
    + div {
      padding-top: 20px;
      border-top: 1px solid #6d738133;
    }
    > * {
      color: #595b62;
      display: flex;
      align-items: center;
      height: 45px;
      padding-left: 32px;
      border-radius: 0px 100px 100px 0px;
      .iconfont {
        font-size: 20px;
        margin-right: 13px;
      }
    }
    .router-link-active {
      background: #8693c91c;
      color: #4285f4;
    }
  }
}
.left_bar {
  width: 236px;
  .first_part {
    padding: 36px 0 39px 30px;
  }
  img {
    height: 69px;
    margin-bottom: 23px;
  }
}
</style>